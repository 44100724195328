<template>
  <div>
    <b-card class="text-black">
      <b-row>
        <b-col>
          <div class="text-right mt-1">
            <b-button
              type="submit"
              variant="info"
              pill
              class="mr-1"
              @click="changeMobile"
            >
              Change Mobile
            </b-button>
            <b-button
              type="submit"
              variant="primary"
              pill
              class="mr-1"
              @click="changePassword"
            >
              Change Password
            </b-button>
          </div>
       
        
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2">
          <div>
            <div class="mb-1">
              <div class="wrapper d-inline-block">
                <b-img :src="profileImage" rounded fluid alt="profile photo" />
                <!-- <b-avatar
                  size="40"
                  variant="light-primary"
                  badge
                  :src="getUser ? getUser.profile_image : ''"
                  class="badge-minimal"
                /> -->
                <div class="w-100">
                  <label class="btn btn-primary btn-sm m-0 btn-block">
                    <b-form-file
                      v-model="uploadImage"
                      class="mt-1"
                      plain
                      accept="image/*"
                      @change="uploadProfileImage"
                      hidden
                    />
                    <span class="align-middle">Upload</span>
                  </label>
                </div>
              </div>
              <small class="text-success" v-if="imgError === 0">
                <feather-icon icon="CheckCircleIcon" class="mr-50" />Image
                uploaded
              </small>
              <small class="text-danger" v-else-if="imgError === 1">
                <feather-icon icon="AlertCircleIcon" class="mr-50" />Size
                greater than 50 kb
              </small>
              <small class="text-danger" v-else-if="imgError === 2">
                <feather-icon icon="AlertCircleIcon" class="mr-50" />Height and
                Width must not exceed 200px.
              </small>
            </div>
            <div>
              <h2
                style="text-align: left"
                class="text-primary font-weight-bold"
              >
                {{ name }}
              </h2>
            </div>
          </div>
        </b-col>
        <b-col md="10" class="text-black">
          <b-row class="p-1">
            <b-col>
              <strong> Full Name :</strong>
            </b-col>
            <b-col>{{ name }}</b-col>
          </b-row>
          <b-row class="p-1">
            <b-col>
              <strong> CNIC : </strong>
            </b-col>
            <b-col>{{ cnic }}</b-col>
          </b-row>
          <!-- <b-row class="p-1">
            <b-col>
              <strong> Email : </strong>
            </b-col>
            <b-col>{{ email }}</b-col>
          </b-row> -->
          <b-row class="p-1">
            <b-col>
              <strong> Mobile # : </strong>
            </b-col>
            <b-col>{{ mobile }}</b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-overlay :show="show" spinner-variant="primary" no-wrap />
    </b-card>
    <change-password-modal
      :key="`createe-${changePasswordModalCount}`"
      @modalClosed="onModalClosedPaswword"
    />
    <change-mobile-modal
      :key="`createe-${changeMobileModalCount}`"
      @modalClosed="onModalClosedMobile"
    />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "../../util";
import { mapGetters, mapActions } from "vuex";
import ProfileEditModal from "@/components/petition/ProfileEditModal.vue";
import ChangePasswordModal from "@/components/petition/ChangePasswordModal.vue";
import Ripple from "vue-ripple-directive";
import ChangeMobileModal from '@/components/petition/ChangeMobileModal.vue';

const dataURItoBlob = (dataURI) => {
  const bytes =
    dataURI.split(",")[0].indexOf("base64") >= 0
      ? atob(dataURI.split(",")[1])
      : unescape(dataURI.split(",")[1]);
  const mime = dataURI.split(",")[0].split(":")[1].split(";")[0];
  const max = bytes.length;
  const ia = new Uint8Array(max);
  for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
  return new Blob([ia], { type: mime });
};
const resizeImage = ({ file, maxSize }) => {
  const reader = new FileReader();
  const image = new Image();
  const canvas = document.createElement("canvas");

  const resize = () => {
    let { width, height } = image;

    if (width > height) {
      if (width > maxSize) {
        height *= maxSize / width;
        width = maxSize;
      }
    } else if (height > maxSize) {
      width *= maxSize / height;
      height = maxSize;
    }

    canvas.width = width;
    canvas.height = height;
    canvas.getContext("2d").drawImage(image, 0, 0, width, height);

    const dataUrl = canvas.toDataURL("image/jpeg");

    return dataURItoBlob(dataUrl);
  };

  return new Promise((ok, no) => {
    if (!file.type.match(/image.*/)) {
      alert("Not a image");
      return;
    }

    reader.onload = (readerEvent) => {
      image.onload = () => ok(resize());
      image.src = readerEvent.target.result;
    };

    reader.readAsDataURL(file);
  });
};

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ProfileEditModal,
    ChangePasswordModal,
    ChangeMobileModal
  },
  mixins: [util],
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      changePasswordModalCount: 0,
      changeMobileModalCount: 0,
      imgError: -1,
      profileImage: "",
      uploadImage: null,
      cnic: null,
      mobile: null,

      gender: "",
      editProfileModalCount: 0,
      originalImg: null,
      resizedImg: null,
      name: null,
      show: false,
    };
  },
  created() {
  },
  async mounted() {
    await this.getUserData();
  },
  methods: {
    ...mapActions({
      updateImage: "appData/updateImage",
      updateProfile: "appData/updateProfile",
      get_patient_detail: "appData/get_patient_detail",
      get_user_detail: "appData/get_user_detail",
    }),
    async getUserData() {
      this.profileImage = this.getUser.profile_image;
      this.gender = this.getUser.gender;
      this.name = this.getUser.name ? this.getUser.name : "";
      this.cnic = this.getUser.cnic ? this.getUser.cnic : "";
      this.mobile = this.getUser.mobile ? this.getUser.mobile : "";
    },
    async uploadProfileImage(file) {
      try {
        this.show = true;
        var image = await resizeImage({
          file: file.target.files[0],
          maxSize: 200,
        });
        this.resizedImg = URL.createObjectURL(image);
        this.uploadImage = image;
        let formData = new FormData();
        formData.append("profile_image", this.uploadImage);
        try {
          const res = await this.updateImage(formData);
          if (res.status === 200) {
            this.$swal({
              title: res.data.msg,
              icon: "success",
              timer: 1500,
            });
            this.profileImage = this.resizedImg;
            this.show = false;
          }
          this.show = false;
        } catch (error) {
          this.show = false;
          this.displayError(error);
        }
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
    },
    tConvert(time) {
      if (time) {
        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)/) || [time];
        try{
          if(time){
            if (time.length > 1) {
          // If time format correct
          time = time.slice(1); // Remove full string match value
          time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
          time[0] = +time[0] % 12 || 12; // Adjust hours
        }
          }
        }
        catch(e){

        }
        return time.join(""); // return adjusted time or original string
      }
    },
    getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    async validationForm() {
      const success = await this.$refs.profileUpdateValidation.validate();
      if (success) {
        await this.submit();
      }
    },
    editProfile() {
      this.editProfileModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("profile-edit-modal");
      });
    },

    async submit() {
      const user = { ...this.getUser };

      user.age = this.age !== "" ? this.age : null;
      user.blood_group = this.selectedBloodGroup;
      user.next_of_kin_name = this.kinName !== "" ? this.kinName : null;
      user.next_of_kin_mobile = this.kinNumber !== "" ? this.kinNumber : null;
      user.gender = this.gender ? this.gender : null;
      user.contact = this.contact !== "" ? this.contact : null;
      user.expertise = this.expertise !== "" ? this.expertise : null;
      user.experience = this.experience !== "" ? this.experience : null;
      user.consultation_time =
        this.consultationTime !== "" ? this.consultationTime : null;
      user.time_per_patient =
        this.timePerPatient !== "" ? this.timePerPatient : null;
      user.qualification =
        this.qualification !== "" ? this.qualification : null;
      user.profile_detail =
        this.profileDetail !== "" ? this.profileDetail : null;
      user.pk = user.id;

      try {
        const res = await this.updateProfile(user);
        if (res.status === 200) {
          this.$swal({
            title: res.data.msg,
            icon: "success",
            timer: 1500,
          });
        }
      } catch (error) {
        this.displayError(error);
      }
    },

    
    changePassword() {
      this.changePasswordModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("change-password-modal");
      });
    },
    changeMobile(){
      this.changeMobileModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("change-mobile-modal");
      });
    },

    async onModalClosedPaswword() {
      try {
        await this.getUserData();
      } catch (error) {}
    },
    async onModalClosedMobile() {
      try {
        await this.getUserData();
      } catch (error) {}
    },
  },
  computed: {
    ...mapGetters({
      getUser: "appData/getUser",
      hasRole: "appData/hasRole",
    }),
  },
};
</script>

<style>
.vSelectStyle .vs__deselect {
  fill: #fff !important;
}

[hidden] {
  display: none !important;
}

.wrapper {
  position: relative;
}

.overlay {
  position: absolute;
  bottom: 0px;
  text-align: center;
}
</style>
;
