<template>
  <b-modal
    id="change-mobile-modal"
    title="Mobile Change Modal"
    centered
    hide-footer
    size="md"
    @hidden="reset"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
  >
    <template #modal-title>
      <h2 class="m-0">Change Mobile</h2>
    </template>

    <validation-observer ref="changeMobileValidation">
      <b-form @submit.prevent>
        <b-row>
          <b-col md="8">
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="new-mobile">New Mobile</label>
              </div>
              <validation-provider
                #default="{ errors }"
                name="New Mobile"
                rules="required|integer|length:11"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="new-mobile"
                    v-model="newMobile"
                    :state="errors.length > 0 ? false : null"
                    type="mobile"
                    :disabled="otpSent"
                    class="form-control-merge"
                    name="new-mobile"
                    placeholder="New Mobile"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-button
              type="submit"
              variant="primary"
              pill
              class="mt-2"
              v-if="!otpSent"
              @click="sendOtp"
            >
              Send OTP
            </b-button>
          </b-col>
        </b-row>
        <b-row v-if="otpSent">
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="OTP"
              rules="required|integer|positive|length:6"
            >
              <b-form-group>
                <b-form-input
                  id="plot_no"
                  v-model="otp"
                  placeholder="Enter OTP"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-form-group class="text-right">
            <b-button
              style="margin-left: 3%"
              type="submit"
              variant="primary"
              pill
              class="mr-1"
              @click="validationForm"
            >
              Update
            </b-button>
          </b-form-group>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import util from "../../util";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility, util],
  data() {
    return {
      newMobile: "",
      otp: "",
      otpSent: false,
    };
  },
  async mounted() {
    if (this.profile) {
    }
  },
  methods: {
    ...mapActions("appData", ["sendOtpMobile", "changeMobile"]),
    async validationForm() {
      const success = await this.$refs.changeMobileValidation.validate();
      if (success) {
        await this.submit();
      }
    },
    async sendOtp() {
      const success = await this.$refs.changeMobileValidation.validate();
      if (success) {
        try {
          const res = await this.sendOtpMobile({
            mobile: this.newMobile,
          });
          if (res.status === 200) {
            this.otpSent = true;
          }
        } catch (error) {
          this.displayError(error);
        }
      }
    },
    async submit() {
      try {
        const res = await this.changeMobile({
          otp: this.otp,
          mobile: this.newMobile,
        });
        if (res.status === 200) {
          this.$swal({
            title: res.data.msg,
            icon: "success",
            timer: 1500,
          });
          this.$emit("modalClosed")
          this.$nextTick(() => {
            this.$bvModal.hide("change-mobile-modal");
          });
          
        }
      } catch (error) {
        this.displayError(error);
      }
    },

    reset() {
      this.newMobile = "";
      this.otp = "";
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
    }),
  },
};
</script>

<style>
.vSelectStyle .vs__deselect {
  fill: #fff !important;
}
</style>
